import type { CSSProperties } from 'react';

import React, { useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { VariableSizeList as VirtualList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

// eslint-disable-next-line no-restricted-imports
import type { ProjectContextValue, VersionContextValue } from '@core/context';
// eslint-disable-next-line no-restricted-imports
import { ProjectContext, VersionContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';

import Spinner from '@ui/Spinner';

import classes from './index.module.scss';
import PaddedInnerElement from './PaddedInnerElement';
import useInfiniteLoaderData from './useInfiniteLoaderData';

// Shim in a light context to provide the paddingBlock value to the PaddedInnerElement
interface InfiniteLoaderListContextProps {
  paddingBlock: number;
}
export const InfiniteLoaderListContext = React.createContext<InfiniteLoaderListContextProps>({
  paddingBlock: 0,
});

interface InfiniteLoaderListProps<T> {
  /**
   * A function that returns a React element to be rendered for each item in the list
   */
  children: (props: { index: number; item: T }) => React.ReactNode;
  /**
   * Additional class names to apply to the list container
   */
  className?: string;
  /**
   * The API endpoint to fetch data from
   */
  endpoint: string;
  /**
   * The height of each item in the list. Can be a a static number, or function that returns a height for each item
   * in the list which allows for dynamic item heights.
   */
  itemHeight: number | ((item: T) => number);
  /**
   * Static height of the list container
   */
  listHeight?: number;
  /**
   * A callback that is called every time a new page of data is loaded
   */
  onLoad?: (data: T[]) => void;
  /**
   * A callback that is called once after the first page of data is loaded
   */
  onReady?: () => void;
  /**
   * Top/bottom padding to add to the list container
   */
  paddingBlock?: number;
  /**
   * The number of items to fetch per page
   */
  perPage?: number;
  /**
   * Clear the SWR cache for the paginated data before the component is unmounted.
   */
  resetBeforeUnmount?: boolean;
  /**
   * Threshold at which to pre-fetch data; defaults to 10.
   * A threshold of 10 means that data will start loading when a user scrolls within 10 rows
   * of the end of the loaded data.
   */
  threshold?: number;
}

export interface InfiniteLoaderListRef<T> {
  /**
   * The current SWR data in the paginated response format
   */
  data: ReturnType<typeof useInfiniteLoaderData<T>>['data'];
  /**
   * SWR mutate function to manually trigger a revalidation of the data
   */
  mutate: ReturnType<typeof useInfiniteLoaderData<T>>['mutate'];
}

/**
 * An infinite scrolling virtual list that consumes paginated APIv2 data
 */
function InfiniteLoaderList<ItemType>(
  {
    children,
    className,
    endpoint,
    itemHeight,
    listHeight,
    onLoad,
    onReady,
    perPage = 20,
    threshold = 10,
    paddingBlock = 0,
    resetBeforeUnmount = false,
  }: InfiniteLoaderListProps<ItemType>,
  forwardedRef: React.Ref<InfiniteLoaderListRef<ItemType>>,
) {
  const bem = useClassy(classes, 'InfiniteLoaderList');

  const listContainerRef = useRef<HTMLDivElement | null>(null);
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { version } = useContext(VersionContext) as VersionContextValue;

  const [listContainerHeight, setListContainerHeight] = useState(listHeight || 0);

  // If no listHeight prop is provided, use a ResizeObserver to set the height
  // of the list to fill the available space in the parent container
  useEffect(() => {
    let observer: ResizeObserver;

    if (!listHeight && listContainerRef?.current) {
      observer = new ResizeObserver(([entry]) => {
        let height = entry.contentRect.height;
        if (height === 0) {
          // Fall back to a default height if the parent container has a height of 0
          height = 200;
          // eslint-disable-next-line no-console
          console.warn(
            'InfiniteLoaderList: Parent container has no height set. Falling back to a 200px height. Update your layout styles or set the `listHeight` prop.',
          );
        }
        setListContainerHeight(height);
      });
      observer.observe(listContainerRef?.current);
    }

    return () => observer?.disconnect();
  }, [listContainerRef, listHeight, setListContainerHeight]);

  const { data, items, isReady, infiniteLoaderProps, mutate, reset } = useInfiniteLoaderData<ItemType>(
    `/${project.subdomain}/api-next/v2/versions/${version}${endpoint}`,
    {
      perPage,
      threshold,
    },
  );

  // Expose SWR data and mutate function to parent components
  useImperativeHandle(forwardedRef, () => ({ mutate, data }), [mutate, data]);

  useEffect(() => {
    if (isReady) onReady?.();
  }, [isReady, onReady]);

  useEffect(() => {
    onLoad?.(items);
  }, [items, onLoad]);

  useEffect(() => {
    return () => {
      if (resetBeforeUnmount) {
        reset();
      }
    };
  }, [reset, resetBeforeUnmount]);

  const { itemCount, isItemLoaded } = infiniteLoaderProps;

  const listStyles = useMemo(
    () =>
      ({
        '--InfiniteLoaderList-intial-height': `${listContainerHeight}px`,
      }) as CSSProperties,
    [listContainerHeight],
  );

  const itemSizeCallback = useMemo(
    () => (index: number) => (typeof itemHeight === 'function' ? itemHeight(items[index]) : itemHeight),
    [items, itemHeight],
  );

  return (
    <InfiniteLoaderListContext.Provider value={{ paddingBlock }}>
      <div ref={listContainerRef} className={bem('&')}>
        <div className={bem(!isReady && '-content_loading', className)} style={listStyles}>
          {!isReady ? (
            <Spinner />
          ) : (
            <InfiniteLoader {...infiniteLoaderProps}>
              {({ onItemsRendered, ref }) => {
                return (
                  <VirtualList
                    ref={ref}
                    height={listContainerHeight}
                    innerElementType={PaddedInnerElement}
                    itemCount={itemCount}
                    itemData={items}
                    itemSize={itemSizeCallback}
                    onItemsRendered={onItemsRendered}
                    width="100%"
                  >
                    {({ index, style }) => {
                      const item = items[index];
                      const isLoading = !isItemLoaded(index);
                      return (
                        <li
                          className={bem('-item', isLoading && '-item_loading')}
                          style={{ ...style, top: `calc(${style.top}px + ${paddingBlock}px)` }}
                        >
                          {isLoading ? <Spinner size="sm" /> : children({ item, index })}
                        </li>
                      );
                    }}
                  </VirtualList>
                );
              }}
            </InfiniteLoader>
          )}
        </div>
      </div>
    </InfiniteLoaderListContext.Provider>
  );
}

export default React.forwardRef(InfiniteLoaderList) as <T>(
  props: InfiniteLoaderListProps<T> & { ref?: React.Ref<InfiniteLoaderListRef<T>> },
) => React.ReactElement;
