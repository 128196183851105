import camelCase from 'lodash/camelCase';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useSuperHubStore } from '@core/store';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import { PageNavDivider, PageNavItem, PageNavCategory } from '@ui/Dash/PageNav';

export const ApiReferencePages = React.memo(function ApiReferencePages() {
  const { slug } = useParams<SuperHubRouteParams>();
  const [isReferenceDefinitionsRoute, realtimeData] = useSuperHubStore(s => [
    s.isReferenceDefinitionsRoute,
    s.sidebar.realtimeData,
  ]);

  return (
    <>
      <PageNavItem
        active={isReferenceDefinitionsRoute}
        label="API Definitions"
        showActions={false}
        to="/update/reference"
        type="apiDefinitions"
      />
      <PageNavDivider collapsible={false} includeLine />
      {/* Render RealTime category and page links */}
      {realtimeData?.map(category => (
        <PageNavCategory
          key={category.title}
          label={category.title}
          showActions={false}
          startOpened
          totalChildPages={category.pages.length}
        >
          {category.pages.map(page => (
            <PageNavItem
              key={page.uri}
              active={page.slug === slug}
              isPrivate={page.hidden}
              label={page.title}
              showActions={false}
              type={
                camelCase(`realtime-${page.slug}`) as
                  | 'realtimeAuthentication'
                  | 'realtimeGettingStarted'
                  | 'realtimeMyRequests'
              }
            />
          ))}
        </PageNavCategory>
      ))}
      <PageNavDivider collapsible={false} includeLine />
    </>
  );
});
