import React from 'react';
import { useParams } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import { useProjectStore } from '@core/store';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import DisabledSectionBadge from '@ui/DisabledSectionBadge';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Segment, { SegmentItem } from '@ui/Segment';

import DisplaySettingsForm from './DisplaySettingsForm';
import classes from './index.module.scss';

const navItems = [
  {
    icon: 'book-open',
    label: 'Guides',
    section: 'docs',
  },
  {
    icon: 'recipes',
    label: 'Recipes',
    section: 'recipes',
  },
  {
    icon: 'code',
    label: 'API Reference',
    section: 'reference',
  },
  {
    icon: 'megaphone',
    label: 'Changelog',
    section: 'changelog',
  },
  {
    icon: 'file',
    label: 'Custom Pages',
    section: 'page',
  },
] as const;

interface SectionNavProps {
  className?: string;
}

/**
 * Renders links to navigate between sections while in edit mode.
 */
function SectionNav({ className }: SectionNavProps) {
  const bem = useClassy(classes, 'SectionNav');

  const projectNavLinks = useProjectStore(s => s.data.appearance.navigation.links);

  const { section: routeSection } = useParams<SuperHubRouteParams>();

  const selectedIndex = navItems.findIndex(({ section }) => section === routeSection);

  return (
    <Flex align="center" className={bem('&', className)} justify="center" tag="nav">
      <Segment circular ghost kind="primary" selectedIndex={selectedIndex} size="sm">
        {navItems.map(({ icon, label, section }, index) => {
          const navlink = projectNavLinks[section === 'docs' ? 'guides' : section];
          const isActive = selectedIndex === index;
          const isEnabled = navlink?.visibility === 'enabled';
          const isCustomPages = section === 'page';
          return (
            <SegmentItem
              key={section}
              afterSlot={!isCustomPages && <DisplaySettingsForm isActive={isActive} section={section} />}
              className={bem('-item')}
              to={`/update/${section}`}
            >
              {isCustomPages || isEnabled ? <Icon name={icon} /> : <DisabledSectionBadge />}
              <Flex align="center" gap={0}>
                {navlink?.alias || label}
              </Flex>
            </SegmentItem>
          );
        })}
      </Segment>
    </Flex>
  );
}

export default SectionNav;
