import type { OASDocument, ParameterObject } from 'oas/types';

import produce from 'immer';
import Oas from 'oas';
import React from 'react';

import { upperFirst } from '@core/utils/lodash-micro';

import APISectionHeader from '@ui/API/SectionHeader';
import type { CurrentOperation } from '@ui/APIDesigner/OperationEditor';
import TypeMenu from '@ui/APIDesigner/TypeMenu';
import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import apiDesignerClasses from '../style.module.scss';

interface SchemaTypes {
  id: string;
  onChange: ($TSFixMe, string) => void;
  remove: (idx: string) => void;
  schema: {
    default?: string;
    description?: string;
    format?: string;
    in: 'cookie' | 'header' | 'path' | 'query';
    name: string;
    required: boolean;
    type: 'array' | 'integer' | 'number' | 'object' | 'string';
  };
}

const Schema = ({ id, onChange, remove, schema }: SchemaTypes) => {
  const handleChange = (e, kind: string) => {
    const updatedSchema = { ...schema };
    if (kind === 'name') updatedSchema.name = e.currentTarget.value;
    if (kind === 'description') updatedSchema.description = e.currentTarget.value;
    if (kind === 'required') updatedSchema.required = !schema.required;
    if (kind === 'default') updatedSchema.default = e.currentTarget.value;
    if (kind === 'type') {
      updatedSchema.type = e.newType.type;
      updatedSchema.format = e.newType.format;
    }

    onChange(updatedSchema, id);
  };

  return (
    <Flex align="stretch" className={apiDesignerClasses.Parameter} gap="4px" justify="start" layout="col" tag="form">
      <Flex align="stretch" className={apiDesignerClasses['Parameter-group']} gap="0" layout="col">
        <Flex align="baseline" gap="0">
          <Flex align="baseline" gap="2px" justify="between">
            <input
              className={`${apiDesignerClasses['Parameter-input']} ${apiDesignerClasses['Parameter-input_name']}`}
              data-1p-ignore
              onChange={e => handleChange(e, 'name')}
              placeholder="Name"
              spellCheck="false"
              style={{ width: `${schema.name?.length || '7'}ch` }}
              value={schema.name}
            />

            <Dropdown justify="start">
              <span
                className={`${apiDesignerClasses['Parameter-input']} ${apiDesignerClasses['Parameter-input_type']}`}
              >
                {schema.format ? `${schema.type} (${schema.format})` : schema.type}
                <Icon name="chevron-down" />
              </span>
              <TypeMenu
                format={schema.format}
                setNewType={newType => handleChange({ newType }, 'type')}
                type={schema.type || 'string'}
              />
            </Dropdown>

            {schema.in !== 'path' && (
              <Flex
                className={`${apiDesignerClasses['Parameter-input']} ${
                  apiDesignerClasses['Parameter-input_required']
                } ${schema.required ? apiDesignerClasses['Parameter-input_required_checked'] : ''}`}
                gap="xs"
                tag="label"
              >
                <span>required</span>
                <input
                  checked={schema.required}
                  className={apiDesignerClasses['Parameter-input-checkbox']}
                  onChange={e => handleChange(e, 'required')}
                  type="checkbox"
                />
              </Flex>
            )}
          </Flex>
          <Flex align="center" gap="2px">
            <input
              className={`${apiDesignerClasses['Parameter-input']} ${apiDesignerClasses['Parameter-input_form']}`}
              name="default"
              onChange={e => handleChange(e, 'default')}
              placeholder="Default Value"
              value={schema.default}
            />
            {schema.in !== 'path' && (
              <Button
                className={apiDesignerClasses['Parameter-delete']}
                ghost
                kind="destructive"
                onClick={() => remove(id)}
                size="xs"
              >
                <Icon name="trash" />
              </Button>
            )}
          </Flex>
        </Flex>
        <input
          className={apiDesignerClasses['Parameter-description']}
          onChange={e => handleChange(e, 'description')}
          placeholder="Description"
          value={schema.description || ''}
        />
      </Flex>
    </Flex>
  );
};

interface ParameterGroupProps {
  currentOperation: CurrentOperation;
  in: 'cookie' | 'header' | 'path' | 'query';
  oasJSON: OASDocument;
  setOasJSON: ($TSFixMe) => void;
}

const ParameterGroup = (props: ParameterGroupProps) => {
  const oas = new Oas(props.oasJSON);
  const operation = oas.operation(props.currentOperation.path, props.currentOperation.method);
  const schemas = (operation.schema.parameters || []) as ParameterObject[];

  const addSchema = () => {
    const newSchemas = produce(schemas, draft => {
      draft.push({ in: props.in, name: '', schema: { type: 'string' } });
    });
    const newOas = produce(props.oasJSON, draft => {
      draft.paths![props.currentOperation.path]![props.currentOperation.method]!.parameters = newSchemas;
    });
    props.setOasJSON(newOas);
  };

  const removeSchema = idx => {
    const index = Number(idx);
    const modifiedSchemas = produce(schemas, draft => {
      draft.splice(index, 1);
    });
    const newOas = produce(props.oasJSON, draft => {
      draft.paths![props.currentOperation.path]![props.currentOperation.method]!.parameters = modifiedSchemas;
    });
    props.setOasJSON(newOas);
  };

  const onChange = (newSchema, idx) => {
    // keeps track of which parameter you are editing within the group.
    // ie the 2nd query parameter, the 3rd header parameter, etc.
    const index = Number(idx);

    // convert newSchema to OpenAPIV3_1.ParameterObject
    const newSchemaObject: ParameterObject = {
      name: newSchema.name,
      in: newSchema.in,
      required: newSchema.required,
      description: newSchema.description,
      schema: {
        type: newSchema.type,
        format: newSchema.format,
        default: newSchema.default,
      },
    };

    // Since all types of params are mixed together in oas we need to make sure
    // that we edit the correct one, since the index is based on the location
    const modifiedSchemas = produce(schemas as ParameterObject[], draft => {
      // `counterInLocation` keeps track of how many times a parameter type (query, header)
      // in the `schemas` array matches the one you are editing
      let counterInLocation = 0;
      draft.forEach((param, i) => {
        if (param.in === newSchema.in) {
          if (counterInLocation === index) {
            draft[i] = newSchemaObject;
          }

          counterInLocation += 1;
        }
      });
    });

    const newOas = produce(props.oasJSON, draft => {
      draft.paths![props.currentOperation.path]![props.currentOperation.method]!.parameters = modifiedSchemas;
    });
    props.setOasJSON(newOas);
  };

  let paramsOfType = schemas.filter(param => 'in' in param && param.in === props.in);

  if (props.in === 'path') {
    const expectedPathParams = Array.from(props.currentOperation.path.matchAll(/({([^}]+)})/g), m => m[2]);
    paramsOfType = expectedPathParams.map(param => {
      const pathParam = paramsOfType.find(p => p.name === param);
      return pathParam || { in: 'path', name: param, schema: { type: 'string' } };
    });
  }

  return (
    <section>
      <APISectionHeader heading={`${upperFirst(props.in)} Parameters`}>
        {/* TODO: disable with a tooltip? */}
        {props.in !== 'path' && (
          <Button kind="secondary" onClick={addSchema} outline size="xs">
            <Icon name="plus" />
          </Button>
        )}
      </APISectionHeader>

      <div>
        {paramsOfType.map((param, idx) => {
          // TODO: yay refs
          const parameterObject = param as ParameterObject;
          const paramSchema: SchemaTypes['schema'] = {
            name: parameterObject.name,
            description: parameterObject.description || '',
            required: parameterObject.required || false,
            in: props.in,
            // @ts-ignore
            type: parameterObject.schema?.type || 'string',
            // @ts-ignore
            default: parameterObject.schema?.default || '',
            // @ts-ignore
            format: parameterObject.schema?.format || '',
          };
          return (
            <Schema
              key={idx.toString()}
              id={idx.toString()}
              onChange={onChange}
              remove={removeSchema}
              schema={paramSchema}
            />
          );
        })}
      </div>
    </section>
  );
};

export default ParameterGroup;
