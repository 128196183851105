import type { GitSidebarCategory, GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useMetricsAPIFetcher } from '@core/hooks/useMetricsAPI';
import usePagination from '@core/hooks/usePagination';
import type { SuperHubGuideReferencePage } from '@core/store';
import { useProjectStore, useSuperHubStore } from '@core/store';

// Intentionally making an exception here because it makes the most sense to
// keep these normalizing functions closest to the SuperHub editor form, where
// they are the most relevant.
// eslint-disable-next-line no-restricted-imports
import { normalizeGitPagesToMongo } from '@routes/SuperHub/Editor/Form/shared/WhatsNext';

import DateLine from '@ui/DateLine';
import PageThumbs from '@ui/PageThumbs';
import PaginationControls from '@ui/PaginationControls';
import WhatsNext from '@ui/WhatsNext';

/**
 * Normalizes our git sidebar page objects into the expected format that
 * `PaginationControls` expects, which is a generic data object with a bunch of
 * page document fields.
 */
function normalizeToPaginationControls(data?: GitSidebarPage) {
  if (!data) return undefined;
  return {
    link_external: !!data.link_external,
    link_url: data.link_url ?? '',
    pageType: undefined, // TBD: For real-time pages only
    slug: data.slug,
    title: data.title,
    type: data.type,
  };
}

interface SuperhubDocFooterProps {
  document?: SuperHubGuideReferencePage;
  metricsThumbsEnabled: boolean;
  projectSubdomain: string;
  sidebar: GitSidebarCategory[];
  version: string;
}

/**
 * This is a fork of the @ui/DocFooter/index.jsx component that is used to render the footer of a doc page
 * when the `project.flags.superHub` is enabled.
 *
 * Because SuperHub uses APIv2 data, and the shape of that data is different from internal
 * Hub api data, we need to fork this component to handle the differences.
 */
function SuperhubDocFooter({
  document,
  metricsThumbsEnabled,
  projectSubdomain,
  sidebar,
  version,
}: SuperhubDocFooterProps) {
  const [isReference] = useSuperHubStore(s => [s.routeSection === 'reference']);

  const whatsNextLabel = useProjectStore(s => s.data.appearance.whats_next_label);
  const { next, prev } = usePagination(document, sidebar);
  const location = useLocation();
  const pathname = location?.pathname;

  const metricsFetch = useMetricsAPIFetcher({ version });

  const pageType = isReference ? 'reference' : 'docs';
  const hasNextData = !!document?.content.next.pages.length || !!document?.content.next.description;

  const sendToMetrics = useCallback(
    body => {
      return metricsFetch({
        path: 'create/thumb',
        method: 'POST',
        body,
      });
    },
    [metricsFetch],
  );

  return (
    <React.Fragment key={pathname}>
      <div className="UpdatedAt">
        <DateLine icon="icon icon-watch" prefix="Updated" tag="p" time={document?.updated_at || ''} />
      </div>
      <hr className="NextStepsDivider" />
      {hasNextData ? (
        <WhatsNext
          description={document.content.next.description ?? undefined}
          label={whatsNextLabel ?? undefined}
          pages={normalizeGitPagesToMongo(document.content.next.pages)}
        />
      ) : (
        <PaginationControls
          next={normalizeToPaginationControls(next)}
          pageType={pageType}
          prev={normalizeToPaginationControls(prev)}
        />
      )}
      {!!metricsThumbsEnabled && (
        <PageThumbs pathname={pathname} projectSubdomain={projectSubdomain} sendToMetrics={sendToMetrics} />
      )}
    </React.Fragment>
  );
}

export default SuperhubDocFooter;
