import type { GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import React, { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useSuperHubStore } from '@core/store';

import { useSuperHubContext } from '@routes/SuperHub/Layout/Context';
import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import { PageNavCategoryContext, PageNavItem } from '@ui/Dash/PageNav';
import type { PageNavItemProps } from '@ui/Dash/PageNav';

import { useCollapsedStateContext } from '../CollapsedState';
import { useConfirmDeletionContext } from '../ConfirmDeletion';
import { useDisabledStateContext } from '../DisabledStateContext';

import { SidebarNavNewPage } from './NewPage';
import { SidebarNavPages } from './Pages';

/**
 * Returns the correct page item type based on the provided model.
 */
function getPageItemType(page: GitSidebarPage) {
  if (page.type === 'link') {
    return page.link_external ? 'linkExternal' : 'link';
  }
  if (page.type === 'changelog') return 'changelog';
  return 'page';
}

export interface SidebarNavPageProps {
  children?: React.ReactNode;
  index: number;
  page: GitSidebarPage;
}

export const SidebarNavPage = React.memo(function SidebarNavPage({ index, page }: SidebarNavPageProps) {
  const { routeParentPage, slug } = useParams<SuperHubRouteParams>();
  const { browserRouterHistory } = useSuperHubContext();
  const [duplicatePage, isCreateNewPage, routeSection, reorderPage] = useSuperHubStore(s => [
    s.sidebar.duplicatePage,
    s.editor.isCreateNewPage,
    s.routeSection,
    s.sidebar.reorderPage,
  ]);
  const { setPendingDelete } = useConfirmDeletionContext();
  const { collapsedStateById, updateCollapsedStateById } = useCollapsedStateContext();

  // Grab the parent category title.
  const { label: category } = useContext(PageNavCategoryContext);
  const hasNewSubPage = isCreateNewPage && page.slug === routeParentPage;

  /**
   * Contains the configuration of page menu actions, which toggles actions
   * on/off depending on the page type, e.g. `docs`, `page`, etc.
   */
  const configureActions = useMemo<PageNavItemProps['configureActions']>(() => {
    return ['changelog', 'custom_page'].includes(page.type)
      ? {
          add: { hidden: true },
          // TODO: enable duplication similar to how we duplicate guide pages
          duplicate: { hidden: true },
          move: { hidden: true },
        }
      : undefined;
  }, [page.type]);

  const handleAction = useCallback<NonNullable<PageNavItemProps['onAction']>>(
    ({ action }) => {
      switch (action) {
        case 'add': {
          browserRouterHistory.push(`/create/${routeSection}/${category}/${page.slug}`);
          break;
        }
        case 'delete': {
          setPendingDelete(page);
          break;
        }
        case 'duplicate':
          duplicatePage(page);
          break;
        default:
          break;
      }
    },
    [browserRouterHistory, category, duplicatePage, page, routeSection, setPendingDelete],
  );

  const handleMove = useCallback<NonNullable<PageNavItemProps['onMove']>>(
    ({ source, target }) => {
      reorderPage(source, target);
    },
    [reorderPage],
  );

  const handleToggle = useCallback<NonNullable<PageNavItemProps['onToggle']>>(
    ({ id, isOpen }) => {
      if (!id) return;
      updateCollapsedStateById(id, isOpen);
    },
    [updateCollapsedStateById],
  );

  // We memoize the children prop to PageNavItem in order to prevent unnecessary
  // re-rendering because it is currently expensive to re-render.
  const memoContent = useMemo(() => {
    return (
      <>
        <SidebarNavPages pages={page.pages} />
        <SidebarNavNewPage parent={page.slug} />
      </>
    );
  }, [page.pages, page.slug]);

  const isSidebarDisabled = useDisabledStateContext();

  return (
    <PageNavItem
      active={page.slug === slug}
      childPages={page.pages}
      configureActions={configureActions}
      deprecated={page.deprecated}
      disabled={!page.slug || isSidebarDisabled}
      disableDnd={['changelog', 'custom_page'].includes(page.type)}
      // TODO: fix badges once endpoint api methods are added to sidebar pages
      // https://linear.app/readme-io/issue/RM-10827/sidebar-reference-pages-are-missing-method-key-for-endpoint-pages
      // endpoint={page.type === 'endpoint' ? page?.api?.method : undefined}
      hasNewSubPage={hasNewSubPage}
      id={page.uri}
      isPrivate={page.hidden}
      label={page.title}
      modified={page.updatedAt}
      onAction={handleAction}
      onMove={handleMove}
      onToggle={handleToggle}
      position={index}
      startOpened={collapsedStateById?.[page.slug] ?? true}
      to={`/update/${routeSection}/${page.slug}`}
      type={getPageItemType(page)}
    >
      {memoContent}
    </PageNavItem>
  );
});
