import type { ReadCustomBlockGitCollectionType } from '@readme/api/src/mappings/customblock/types';
import type { ReadGuideType } from '@readme/api/src/mappings/page/guide/types';
import type { GitSidebarCategory } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { HubResponseProps, RMDXOpts } from '@readme/iso';

import React, { useContext } from 'react';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext, VersionContext } from '@core/context';
import {
  ConnectSuperHubDocumentToApi,
  ConnectSuperHubSidebarToApi,
  InitializeSuperHubDocument,
  useProjectStore,
  useRdmdStore,
  useSuperHubStore,
} from '@core/store';
import ScrollTop from '@core/utils/ScrollTop';

import DocBody from '@routes/Doc/Body';
import Header from '@routes/Doc/Header';
import '@routes/Doc/style.scss';

import Footer from '@ui/DocFooter/SuperhubDocFooter';
import Sidebar from '@ui/Sidebar';

import ConnectRoute from '../ConnectRoute';
import RedirectToEmptyParentChild from '../RedirectToEmptyParentChild';
import RedirectToSidebarFirstPage from '../RedirectToSidebarFirstPage';

import EmptyGuide from './Empty';

/**
 * Shape of route specific data for guides pages.
 */
export interface DocRouteProps {
  apiDefinitions?: never;
  customBlocks: ReadCustomBlockGitCollectionType['data'];
  document: ReadGuideType['data'];
  sidebar: GitSidebarCategory[];
}

function Content({ document: initialDocument, sidebar: initialSidebar, ...props }: HubResponseProps<DocRouteProps>) {
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { version } = useContext(VersionContext);

  const [projectSubdomain, hideTOC] = useProjectStore(s => [
    s.data.subdomain,
    s.data.appearance.table_of_contents === 'disabled',
  ]);

  const [isLoading, isLoadingSidebar, document, sidebar] = useSuperHubStore(s => {
    return [
      // Only consider the document + sidebar to be "loading" if the initial SSR
      // data is different than what's currently in our store. This prevents an
      // unnecessary FOUC or unnecessary loading spinners on initial page load.
      s.document.isLoading && s.document.data !== initialDocument,
      s.sidebar.isLoading && s.sidebar.data !== initialSidebar,
      s.document.getGuidesPageData(),
      s.sidebar.data,
    ];
  });

  const rdmdOpts = useRdmdStore(s => s.opts as RMDXOpts);

  if (!isLoading && !isLoadingSidebar && !document) {
    return <EmptyGuide />;
  }

  return (
    <main className="rm-Guides" id="content">
      <div className="rm-Container rm-Container_flex">
        {!isLoadingSidebar && <Sidebar activeDoc={document?.slug} categories={sidebar} pathRoot="docs" />}
        <article className={`rm-Article ${isLoading || isLoadingSidebar ? 'rm-Guides_loading' : ''}`}>
          <Header
            excerpt={document?.content.excerpt || ''}
            lang={props.lang}
            slugUrl={document?.slug}
            suggestedEdits={false}
            title={document?.title}
          />
          {!!document && (
            <DocBody
              docBody={document?.content?.body || ''}
              footer={
                <Footer
                  document={document ?? undefined}
                  metricsThumbsEnabled={
                    // TODO: Add this to APIv2 project mapper so we can read
                    // this from ProjectStore instead of ProjectContext.
                    project.metrics.thumbsEnabled
                  }
                  projectSubdomain={projectSubdomain}
                  sidebar={sidebar}
                  version={version}
                />
              }
              hideTOC={hideTOC}
              mdx
              opts={rdmdOpts}
            />
          )}
          <div className="ModalWrapper" id="tutorialmodal-root" />
        </article>
      </div>
    </main>
  );
}

/**
 * This is a fork of the @routes/Doc/index.jsx component that is used to render the a `/docs/:slug` page
 * when the `project.flags.superHub` is enabled.
 *
 * Because SuperHub uses APIv2 data, and the shape of that data is different from internal
 * Hub api data, we need to fork this component to handle the differences.
 */
export default function SuperHubDoc(props: HubResponseProps<DocRouteProps>) {
  const { document: initialDocument, customBlocks: initialCustomBlocks, sidebar: initialSidebar } = props;

  return (
    <ConnectSuperHubSidebarToApi initialSidebar={initialSidebar}>
      <RedirectToSidebarFirstPage>
        <RedirectToEmptyParentChild>
          <InitializeSuperHubDocument customBlocks={initialCustomBlocks} document={initialDocument}>
            <ConnectSuperHubDocumentToApi>
              <ScrollTop smooth />
              <ConnectRoute next={Content} props={props} />
            </ConnectSuperHubDocumentToApi>
          </InitializeSuperHubDocument>
        </RedirectToEmptyParentChild>
      </RedirectToSidebarFirstPage>
    </ConnectSuperHubSidebarToApi>
  );
}
