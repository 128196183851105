import type { ReadAPIDefinitionCollectionType, APIDefinitionsReadType } from '@readme/api/src/mappings/apis/types';

import produce from 'immer';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import useReadmeApi, { fetcher } from '@core/hooks/useReadmeApi';
import { useSuperHubStore } from '@core/store';

import Flex from '@ui/Flex';

import ApiDefinitionForm from './Form';
import styles from './index.module.scss';
import ApiDefinitionList from './List';

export interface ApiDefinitionProps {
  /**
   * Represents the initial definitions collection to use while initializing
   * connection to our API endpoint to then continually hydrate data.
   */
  apiDefinitions?: ReadAPIDefinitionCollectionType;
  document?: never;
}

const emptyCollection = {
  data: [],
  total: 0,
};

/**
 * Entrypoint route component for managing API definitions.
 */
const ApiDefinition: React.FC<ApiDefinitionProps> = ({ apiDefinitions: initialDefinitions }) => {
  const bem = useClassy(styles, 'ApiDefinition');
  const [formAction, setFormAction] = useState<'create' | 'update' | null>(null);
  const [formDefinition, setFormDefinition] = useState<APIDefinitionsReadType | null>(null);
  const [apiBaseUrl, isSuperHubDevelopment, revalidateSidebar] = useSuperHubStore(s => [
    s.apiBaseUrl,
    s.isSuperHubDevelopment,
    s.sidebar.revalidate,
  ]);

  /**
   * Contains previously fetched collection data so we can overlay our loading
   * state on top of existing data instead of a blank screen.
   * @todo Replace this with SWR option "keepPreviousData" when we upgrade SWR
   * to a newer version that includes this feature.
   * @link https://swr.vercel.app/docs/advanced/understanding#return-previous-data-for-better-ux
   */
  const previousCollection = useRef<ReadAPIDefinitionCollectionType>(initialDefinitions || emptyCollection);

  const {
    data: collection = previousCollection.current,
    isLoading: isCollectionLoading,
    mutate,
  } = useReadmeApi<ReadAPIDefinitionCollectionType>('/apis', {
    apiBaseUrl,
    swr: {
      revalidateOnFocus: true,
      shouldRetryOnError: true,
    },
  });
  const isLoading = isCollectionLoading && collection !== initialDefinitions;
  const isEmptyState = !isLoading && !collection.total;

  useEffect(() => {
    previousCollection.current = collection;
  }, [collection]);

  /**
   * Called whenever API definitions has been mutated in some way. Mainly used
   * to invoke revalidations of data in our definitions list and sidebar.
   */
  const handleChange = useCallback(() => {
    mutate();
    revalidateSidebar();
  }, [mutate, revalidateSidebar]);

  /**
   * Called when deleting an API definition.
   */
  const handleDelete = useCallback(
    async (definition: APIDefinitionsReadType) => {
      const next = produce(collection, draft => {
        const index = draft.data.findIndex(d => definition.uri === d.uri);
        if (index >= 0) {
          draft.data.splice(index, 1);
          draft.total = draft.data.length;
        }
      });

      await mutate(
        async () => {
          await fetcher(`${apiBaseUrl}/apis/${definition.filename}`, {
            method: 'DELETE',
          });
          return next;
        },
        {
          optimisticData: next,
          revalidate: false,
        },
      );

      // Invoke change handler to take care of revalidations.
      handleChange();
    },
    [apiBaseUrl, collection, handleChange, mutate],
  );

  return (
    <Flex align="stretch" className={bem()} gap="md" justify="center" layout="col">
      {isEmptyState ? (
        // TODO: Empty state. Is it correct to show the same "setup" form to
        // prompt users to create a definition?
        <ApiDefinitionForm action="setup" allowManualEditor={isSuperHubDevelopment} onChange={handleChange} />
      ) : formAction ? (
        <ApiDefinitionForm
          action={formAction}
          allowManualEditor={formAction === 'create' && isSuperHubDevelopment}
          definition={formDefinition}
          onCancel={() => setFormAction(null)}
          onChange={handleChange}
        />
      ) : (
        <ApiDefinitionList
          className={bem('-list')}
          collection={collection}
          onCreate={() => {
            setFormAction('create');
            setFormDefinition(null);
          }}
          onDelete={handleDelete}
          onReplace={definition => {
            setFormAction('update');
            setFormDefinition(definition);
          }}
        />
      )}
    </Flex>
  );
};

export default ApiDefinition;
