import type { CurrentOperation } from './OperationEditor';
import type { OASDocument } from 'oas/types';

import React, { useState } from 'react';

import OperationEditor from '@ui/APIDesigner/OperationEditor';
import ParameterGroup from '@ui/APIDesigner/ParameterGroup';
import RequestBodyEditor from '@ui/APIDesigner/RequestBodyEditor';
import Flex from '@ui/Flex';

import classes from './style.module.scss';

interface APIDesignerProps {
  initialOperation: CurrentOperation;
  oas: OASDocument;
  setOasJSON: (oas: OASDocument) => void;
}

const APIDesigner = ({ oas, setOasJSON, initialOperation }: APIDesignerProps) => {
  const [currentOperation, setCurrentOperation] = useState(initialOperation);

  return (
    <Flex align="stretch" gap="md" layout="col">
      <Flex align="stretch" className={classes.APIDesigner} gap="md" layout="col" tag="section">
        <Flex align="stretch" gap="0" justify="start" layout="col">
          <OperationEditor
            currentOperation={currentOperation}
            oasJSON={oas}
            setCurrentOperation={setCurrentOperation}
            setOasJSON={setOasJSON}
          />
          <ParameterGroup currentOperation={currentOperation} in="path" oasJSON={oas} setOasJSON={setOasJSON} />
          <ParameterGroup currentOperation={currentOperation} in="query" oasJSON={oas} setOasJSON={setOasJSON} />
          <ParameterGroup currentOperation={currentOperation} in="header" oasJSON={oas} setOasJSON={setOasJSON} />
          <RequestBodyEditor currentOperation={currentOperation} oasJSON={oas} setOasJSON={setOasJSON} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default APIDesigner;
