import type { OASDocument, HttpMethods } from 'oas/types';

import produce from 'immer';
import React from 'react';

import useClassy from '@core/hooks/useClassy';

import APIMethod from '@ui/API/Method';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import classes from './style.module.scss';

export interface CurrentOperation {
  method: HttpMethods;
  path: string;
}

interface OperationEditorProps {
  currentOperation: CurrentOperation;
  oasJSON: OASDocument;
  setCurrentOperation: (CurrentOperation) => void;
  setOasJSON: ($TSFixMe) => void;
}

const httpMethods = ['get', 'post', 'put', 'patch', 'delete', 'head', 'options', 'trace'] as const;

const OperationEditor = (props: OperationEditorProps) => {
  const path = props.currentOperation.path;
  const method = props.currentOperation.method;

  const bem = useClassy(classes, 'OperationEditor');

  if (!props.oasJSON || !props.oasJSON.paths) {
    // There isn't anything in the OAS document yet
    // Do we need to handle this? How should we?
    return null;
  }

  const changeMethod = e => {
    const newMethod = e.target.value;
    const newOas = produce(props.oasJSON, draft => {
      if (!draft.paths || !draft.paths[path] || !draft.paths[path]?.[method]) {
        return;
      }

      // TODO: Is it correct to be using the ! that way?
      // I'm checking if it exists in like three places even though I know it does
      draft.paths[path]![newMethod] = draft.paths[path]![method];
      delete draft.paths[path]![method];
    });
    props.setCurrentOperation({ ...props.currentOperation, method: newMethod });
    props.setOasJSON(newOas);
  };

  const changeUri = e => {
    const newPath = e.target.value;
    const newOas = produce(props.oasJSON, draft => {
      if (!draft.paths || !draft.paths[path]) {
        return;
      }
      draft.paths[newPath] = draft.paths[path];
      delete draft.paths[path];
    });
    props.setCurrentOperation({ ...props.currentOperation, path: newPath });
    props.setOasJSON(newOas);
  };

  return (
    <Flex align="center" className={bem()} gap="xs" justify="start" tag="form">
      <label className={bem('-method')}>
        <APIMethod
          className={bem('-method-pill')}
          suffix={<Icon className={bem('-icon')} name="chevron-down" />}
          type={method as HttpMethods}
        />
        <select className={bem('-method-select')} defaultValue={method} name="path_method" onChange={changeMethod}>
          {Object.values(httpMethods).map(m => {
            return (
              <option key={m} value={m}>
                {m.toUpperCase()}
              </option>
            );
          })}
        </select>
      </label>

      <Flex gap="0">
        <span>{props.oasJSON?.servers?.[0]?.url}</span>
        <input className={bem('-input')} onChange={changeUri} value={path} />
      </Flex>
    </Flex>
  );
};

export default OperationEditor;
